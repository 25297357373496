<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!--            <label>显示</label>-->
            <!--            <v-select-->
            <!--              v-model="limit"-->
            <!--              :options="limitOptions"-->
            <!--              :clearable="false"-->
            <!--              class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>条</label>-->
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body">
                  <span><strong>店铺名称:{{ storeInformation.store_name==null?storeInformation.company_name:storeInformation.store_name }}</strong> </span>
                </div>
              </b-alert>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  class="mr-1"
                  :to="{ name: 'apps-storesales-edit',query:{storeId:store_id,type:type}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              <b-button
                  variant="primary"
                  :to="{ name: type==1?'apps-store-list':'apps-company-channel-list',query: {type: 1}}"
              >
                <span class="text-nowrap">返回列表</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(store_id)="data">
          #[{{data.item.store_id}}]{{ getCodeLabel('store', data.item.store_id) }}
        </template>

        <template #cell(user_id)="data">
          {{ getCodeLabel('user', data.item.user_id) }}
        </template>

        <template #cell(user_mobile)="data">
          {{ data.item.ext.user.mobile }}
        </template>


        <template #cell(is_default)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_default==0"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-storesales-edit', query: { id: data.item.id,storeId:store_id,type:type } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showDelModal(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
            <b-dropdown-item @click="pushBBM(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">推送至BBMall</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="modal-sales"
        ok-title="删除"
        cancel-title="取消"
        centered
        size="lg"
        @ok="changeState"
        ref="delModal"
    >
      <b-card>
        <template>
          <span>确定删除联系人:{{ getCodeLabel('user', this.salesID) }}吗？</span>
        </template>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import storesalesUseList from './storesalesUseList'
import storesalesStore from './storesalesStore'
import storeStore from "@/views/apps/store/storeStore";
import {useRouter} from "@core/utils/utils";
import companyStore from "@/views/apps/company/companyStore";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    useToast,
  },
  methods: {
    showDelModal(data){
      this.salesID = data.user_id
      this.infID = data.id
      this.$refs['delModal'].show()
    },
  },
  data() {
    return {
      storeInformation: {},
      store_id: 0,
      type:0,
      salesID:'',
      infID:'',
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('storesales')) store.registerModule('storesales', storesalesStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('storesales')) store.unregisterModule('storesales')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const router = useRouter()

    const getStore = function () {
      store.dispatch(this.type===1?'store/view':'company/view', {id: router.route.value.query.storeId}).then(res => {
        this.storeInformation = res.data.data
      })
    }

    const changeState = function () {
      store.dispatch('storesales/state', {id: this.infID, state: 0}).then(res => {
        toast.success('已删除!')
        this.$router.push({ name: 'apps-store-list'})
      })
    }

    const pushBBM = function (data){
      store.dispatch('store/pushBDToBBM', {id: data.store_id,user_id:data.user_id}).then(res => {
        if(res.data.code == 0){
          toast.success("推送成功")
        }else {
          toast.error(res.data.data)
        }
        refetchData()
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = storesalesUseList({
      storeId:router.route.value.query.storeId,
      type:router.route.value.query.type
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeState,
      pushBBM,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      getStore,
    }
  },
  created() {
    this.store_id = this.$route.query.storeId || 0
    this.type = this.$route.query.type || 0
    this.getStore()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
